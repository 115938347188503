import React, { useState } from "react";
import { Link } from "gatsby";

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Collapse from '@material-ui/core/Collapse';


import LockIcon from '@material-ui/icons/Lock';
import OfferIcon from '@material-ui/icons/LocalOffer';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import FaqIcon from '@material-ui/icons/QuestionAnswer';
import ContactIcon from '@material-ui/icons/ContactMail';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CarIcon from '@material-ui/icons/DirectionsCar';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Box } from "@material-ui/core";

import Logo from './Logo';

import { actions as userActions } from '../services/user';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        width: 250,
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const DrawerMenu: React.FC<Props> = ({ open, onClose, onOpen }) => {
  const classes = useStyles();
  const [ userExpanded, setUserExpanded ] = useState(false);
  const [ logoutInProgress, setLogoutInProgress ] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const toggleUserExpanded = () => {
    setUserExpanded(!userExpanded);
  }

  const logout = async () => {
    setLogoutInProgress(true);
    await dispatch(userActions.signOut());
    setLogoutInProgress(false);
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={classes.root}>
        <List>
          <ListItem>
            <Container>
              <Box color="primary.main">
                <Logo variant="normal" />
              </Box>
            </Container>
          </ListItem>
          <Divider />
          {
            user === null ? (<ListItem button component={Link} to="/app/sign-in">
              <ListItemIcon><LockIcon color="secondary" /></ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItem>) : (
                <React.Fragment>
                  <ListItem button onClick={toggleUserExpanded}>
                    <ListItemIcon><ProfileIcon color="secondary" /></ListItemIcon>
                    <ListItemText primary={user.firstName ? `${user.firstName}` : user.phone} />
                    {userExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={userExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested} component={Link} to="/app/my-account">
                        <ListItemText primary="My Account" />
                      </ListItem>
                      <ListItem button className={classes.nested} disabled={logoutInProgress} onClick={logout}>
                        <ListItemText primary="Sign Out" />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              )
          }
          <ListItem button component={Link} to="/app/my-offers">
            <ListItemIcon><OfferIcon color="secondary" /></ListItemIcon>
            <ListItemText primary="My Offers" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/how-it-works">
            <ListItemIcon><HelpIcon color="primary" /></ListItemIcon>
            <ListItemText primary="How it works" />
          </ListItem>
          <ListItem button component={Link} to="/faqs">
            <ListItemIcon><FaqIcon color="primary" /></ListItemIcon>
            <ListItemText primary="FAQs" />
          </ListItem>
          <ListItem button component={Link} to="/contact-us">
            <ListItemIcon><ContactIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Contact us" />
          </ListItem>
          <Divider/>
          <ListItem button component={Link} to="/cars">
            <ListItemIcon><CarIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Car Specs" />
          </ListItem>

        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default DrawerMenu;