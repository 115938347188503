import React from "react";

import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Toolbar, Typography, IconButton, Link as MuiLink, Box } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";

import InstagramIcon from '../icons/instagram.svg';
import FacebookIcon from '../icons/facebook.svg';
import YoutubeIcon from '../icons/youtube.svg';
import TwitterIcon from '../icons/twitter.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: '#f0f0f0',
      },

      content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 2),
        [ theme.breakpoints.up('sm') ]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },

        '& a': {
          color: '#ccc',
        }
      },
      icon: {
        fill: '#ccc',
        width: 45,
      },

      socialIcons: {
        minWidth: 100,
        textAlign: 'center',
      },

      legal: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      copyright: {
        marginRight: theme.spacing(0),
        [ theme.breakpoints.up(470) ]: {
          marginRight: theme.spacing(2),
        }
      }
    }
  ),
);

interface Data {
  site: {
    siteMetadata: {
      siteName: string;
      shortName: string;
    }
  }
}

interface Props {
  bottomMargin: number;
}

const Footer: React.FC<Props> = ({ bottomMargin = 0 }) => {
  const classes = useStyles();
  const year = new Date().getFullYear();

  const data: Data = useStaticQuery(graphql`
    query FooterQUery {
      site {
        siteMetadata {
          siteName,
          shortName
        }
      }
    }
  `);

  return (
    <div className={classes.root} style={{ marginBottom: bottomMargin }}>
      <Toolbar className={classes.content}>

        <div className={classes.legal}>
          <Typography variant="body2" noWrap className={classes.copyright}>Copyright © {year} {data.site.siteMetadata.shortName}.</Typography>
          <Box>
            <MuiLink component={Link} to="/terms" color="inherit">Terms & Conditiona</MuiLink>
            <Typography variant="caption">{' | '}</Typography>
            <MuiLink component={Link} to="/privacy" color="inherit">Privacy Policy</MuiLink>
          </Box>

        </div>

        <div className={classes.socialIcons}>
          <IconButton color="secondary" component="a" href="https://www.facebook.com/leasemojo/" target="_blank" className={classes.icon}>
            <FacebookIcon className={classes.icon} />
          </IconButton>
          <IconButton color="secondary" component="a" href="https://www.instagram.com/leasemojo/" target="_blank" className={classes.icon}>
            <InstagramIcon className={classes.icon} />
          </IconButton>
          <IconButton color="secondary" component="a" href="https://twitter.com/LeaseMojo" target="_blank" className={classes.icon}>
            <TwitterIcon className={classes.icon} />
          </IconButton>
          {/* <IconButton color="secondary" component="a" href="https://www.youtube.com" target="_blank" className={classes.icon}>
            <YoutubeIcon className={classes.icon} />
          </IconButton> */}
        </div>
      </Toolbar>
    </div>
  );
};

export default Footer;