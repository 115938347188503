import React, { useState, ReactNode } from "react"
import clsx from "clsx"

import { Helmet } from "react-helmet";


import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"


import { makeStyles, createStyles } from "@material-ui/styles"
import { Container, Theme, Button, Typography, Box } from "@material-ui/core"
import Logo from "./Logo"

import DrawerMenu from './DrawerMenu';
import Footer from "./Footer"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../store"

import { actions as userActions } from '../services/user';
import { useStaticQuery, graphql } from "gatsby";



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      menuButton: {
        marginRight: theme.spacing(1),
      },
      toolbarPadding: {
        height: 56,
        [ theme.breakpoints.up('sm') ]: {
          height: 64,
        },
      },
      appBar: {
        transition: "all 300ms",
      },
      appBarTransparent: {
        backgroundColor: "transparent",
      },

      logo: {
        flexGrow: 1,
        transition: "opacity 200ms",
      },

      logoTransparent: {
        opacity: 0,
      },

      actions: {
        transition: "opacity 200ms",
      },
      transparent: {
        opacity: 0,
      },
      animated: {
        transition: "transform 300ms",
      },
      hidden: {
        transform: 'translateY(-200%)',
      },
      content: {
        // paddingBottom: theme.spacing(4),
        minHeight: 'calc(100vh - 55px)',
        [ theme.breakpoints.up('sm') ]: {
          minHeight: 'calc(100vh - 64px)',
        },
      },

      recaptcha: {
        position: 'absolute',
        bottom: 64,
        right: 0,
        width: 50,
        height: 50,
        visibility: 'hidden',
      },
      notificationButton: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
      warningBox: {
        height: 40,
        backgroundColor: theme.palette.error.light,
        color: '#fff',
      }
    }
  ),
);

interface LayoutProps {
  addPadding?: boolean;
  appBarTransparent?: boolean;
  scrollThreshold?: number;
  contentClassName?: string;
  bottomMargin?: number;
  actionButton?: ReactNode;
  title?: string;
  ogImage?: string;
  seoDescription?: string;
}

interface Data {
  site: {
    siteMetadata: {
      siteName: string;
      shortName: string;
    }
  }
}




const Layout: React.FC<LayoutProps> = ({
  addPadding = true,
  appBarTransparent = false,
  scrollThreshold,
  contentClassName,
  children,
  bottomMargin = 0,
  actionButton,
  title,
  ogImage,
  seoDescription
}) => {

  const classes = useStyles();

  const data: Data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          shortName
        }
      }
    }
  `);

  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: scrollThreshold,
  })

  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const dispatch = useDispatch();

  const pushDisabled = useSelector((state: RootState) => state.user.pushDisabled);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const requestPermission = () => {
    dispatch(userActions.requestPushPermission());
  }

  const siteName = data.site.siteMetadata.shortName;



  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title ? `${title} - ${siteName}` : `${siteName} - Easy Car Leasing`}
        </title>
        <meta property="og:title" content={title ? `${title} - ${siteName}` : `${siteName} - Easy Car Leasing`} />
        <meta name="description" content={seoDescription ? seoDescription : 'We make choosing and leasing your perfect car easy and enjoyable. By comparing the best offers from our dealers, you get the car you want, from the dealer you like best, at a price that’s right for you.'} />
        <meta property="og:image" content={ogImage ? ogImage : `${process.env.SITE_URL}/assets/bg/bmw-x5-2.jpg`} />
      </Helmet>
      <Container maxWidth="md">
        <AppBar
          position="fixed"
          elevation={scrolled ? 4 : 0}
          className={clsx(classes.appBar, {
            [ classes.appBarTransparent ]: !scrolled && appBarTransparent,
          })}
        >
          {/* {
            pushDisabled ? (
              <Toolbar className={classes.warningBox}>
                <Typography variant="body2">
                  Noticiations are disabled. Please allow to receive offers.
            </Typography>
                <Button variant="outlined" onClick={requestPermission} size="small">Allow</Button>
              </Toolbar>
            ) : null
          } */}
          <Toolbar>

            <Box display="flex" alignItems="center" flex="1">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Logo link variant="normal" className={clsx(classes.logo, classes.animated,
                {
                  [ classes.hidden ]: !scrolled && appBarTransparent,
                })} />
            </Box>

            {
              actionButton ? (
                <Box className={clsx(classes.actions, classes.animated, { [ classes.hidden ]: !scrolled && appBarTransparent })}>
                  {actionButton}
                </Box>
              ) : null
            }

          </Toolbar>
        </AppBar>
      </Container>
      <DrawerMenu open={drawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer} />
      {addPadding ? <div className={classes.toolbarPadding}></div> : null}
      <div className={clsx(classes.content, contentClassName)}>
        {children}
      </div>
      <Footer bottomMargin={bottomMargin} />
      <div id="recaptcha" className={classes.recaptcha}></div>
    </React.Fragment>
  )
}


export default Layout;